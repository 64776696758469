import * as React from "react"
import {graphql} from 'gatsby';
import {useI18next} from 'gatsby-plugin-react-i18next';
import Seo from '../components/Seo';
import Layout from '../components/layout/Layout';
import TermsOfServiceEN from "../components/TermsOfServiceEN";
import TermsOfServiceVI from "../components/TermsOfServiceVI";

const TermsOfServicePage = () => {
  const {language} = useI18next();

  return (
    <Layout>
      <Seo />
      {language.substr(0,2).toLowerCase() === 'vi' ? <TermsOfServiceVI /> : <TermsOfServiceEN />}
    </Layout>
  );
};

export default TermsOfServicePage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}, ns: {in: ["translation", "termsofservice"]}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
