import React from 'react';
import Divider from './Divider';
import {useTranslation} from 'gatsby-plugin-react-i18next';

const TermsOfServiceVI = () => {
  const {t} = useTranslation();
  return(
    <>
      <section className="text-gray-600 bg-gray-100 body-font pt-10">
        <div className="my-cont doc-para">
          <Divider title={t('sub.title')} />
          <p>Các điều khoản sau đây ("Điều khoản dịch vụ") mô tả các điều khoản và điều kiện áp dụng cho việc bạn truy cập và sử dụng ứng dụng, dịch vụ IOHUB cũng như bất kỳ phần mềm và sản phẩm liên quan nào (gọi chung là "Dịch vụ"). Tài liệu này là một thỏa thuận ràng buộc về mặt pháp lý giữa bạn và Công ty TNHH Công nghệ IOHUB và các Chi nhánh của chúng tôi (được gọi là "chúng tôi", "của chúng tôi", hoặc "IOHUB"), trong đó "Chi nhánh" có nghĩa là bất kỳ tổ chức nào kiểm soát trực tiếp hoặc gián tiếp, đều được kiểm soát bởi hoặc nằm dưới sự kiểm soát chung với Công ty TNHH Công nghệ IOHUB. Để truy cập và sử dụng Dịch vụ, bạn phải có tài khoản trực tuyến IOHUB đang hoạt động ("Tài khoản IOHUB"). Khi chúng tôi viết "bạn" hoặc "của bạn", chúng có nghĩa là chủ sở hữu Tài khoản IOHUB đăng ký Dịch vụ.</p>
          <p>Các Điều khoản Dịch vụ này áp dụng đối với tất cả các Dịch vụ được sở hữu và cung cấp bởi IOHUB hoặc các Chi nhánh của chúng tôi, bao gồm nhưng không giới hạn: IOHUB Workflow, và các ứng dụng liên quan. Bằng cách đăng ký Dịch vụ, bạn đồng ý bị ràng buộc bởi Điều khoản Dịch vụ. Bất kỳ tính năng hoặc công cụ mới nào được thêm vào Dịch vụ hiện tại cũng phải tuân theo Điều khoản Dịch vụ. Bạn có thể xem lại phiên bản hiện tại của Điều khoản dịch vụ bất cứ lúc nào tại đây: <a href="https://iohub.tech/vi/termsofservice">https://iohub.tech/vi/termsofservice</a>. Chúng tôi có quyền cập nhật và thay đổi Điều khoản Dịch vụ bằng cách đăng các cập nhật và thay đổi. Bạn nên kiểm tra Điều khoản Dịch vụ thường xuyên để nhận biết bất kỳ cập nhật hoặc thay đổi nào có thể ảnh hưởng đến bạn.</p>
          <p>Bạn phải đọc, đồng ý và chấp nhận tất cả các điều khoản và điều kiện có trong thỏa thuận Điều khoản Dịch vụ này và Chính sách Bảo mật của chúng tôi trước khi bạn có thể sử dụng Dịch vụ.</p>
        </div>
      </section>
      <section className="text-gray-600 body-font">
        <div className="my-cont doc-para">
					<div className="doc-para-item">
            <h2>1. Định nghĩa</h2>
            <ol>
              <li><strong>Nội dung của khách hàng</strong> có nghĩa là tất cả thông tin và dữ liệu (bao gồm văn bản, cuộc trò chuyện của khách hàng, cuộc trò chuyện của nhân viên, hình ảnh, ảnh, video, âm thanh và tài liệu) hoặc bất kỳ nội dung nào khác ở bất kỳ phương tiện hoặc định dạng nào do bạn cung cấp hoặc thay mặt bạn cung cấp liên quan đến việc bạn sử dụng Dịch vụ, bao gồm thông tin và dữ liệu có sẵn trong Tài khoản IOHUB của bạn, nó có thể bao gồm thông tin hoặc dữ liệu từ Nền Tảng Được Hỗ Trợ được cài đặt trên Tài khoản IOHUB của bạn.</li>
              <li><strong>IOHUB Workflow</strong> có nghĩa là trang web IOHUB nơi bạn có thể chọn và cài đặt các ứng dụng trên Tài khoản IOHUB của mình, có sẵn tại: <a href="https://app.iohub.tech">https://app.iohub.tech</a>
              </li>
              <li><strong>Nhân viên</strong> có nghĩa là một hoặc nhiều tài khoản nhân viên mà Chủ sở hữu Tài khoản (như được định nghĩa trong Điều khoản dịch vụ) có thể tạo để cho phép người khác truy cập Tài khoản IOHUB.
              </li>
              <li><strong>(Các) Nền Tảng Được Hỗ Trợ</strong> có nghĩa là phần mềm, trình kết nối, ứng dụng, sản phẩm hoặc dịch vụ của bên thứ ba hiện đang được hỗ trợ bởi Dịch vụ, có thể có sẵn trong IOHUB Workflow, bao gồm IOHUB như được mô tả chi tiết hơn bên dưới và trên trang web IOHUB, có sẵn tại <a href="https://iohub.tech">https://iohub.tech</a> ("Trang web")</li>
            </ol>
					</div>
					<div className="doc-para-item">
            <h2>2. Tài khoản IOHUB</h2>
            <ol>
              <li>Để truy cập và sử dụng Dịch vụ, bạn phải đăng nhập bằng thông tin đăng nhập Tài khoản IOHUB của bạn, bao gồm địa chỉ email của bạn và bất kỳ thông tin nào khác được xác định là bắt buộc. Bạn đồng ý duy trì thông tin chính xác, đầy đủ và cập nhật cho Tài khoản IOHUB của mình. Việc bạn không duy trì thông tin tài khoản IOHUB chính xác, đầy đủ và cập nhật có thể dẫn đến việc chấm dứt Tài khoản IOHUB của bạn và/hoặc bạn không thể truy cập và sử dụng Dịch vụ.
              </li>
              <li>Là người dùng Tài khoản IOHUB, bạn cũng phải tuân theo Điều khoản dịch vụ, Chính sách bảo mật của IOHUB, và bất kỳ điều khoản áp dụng nào khác. Nếu có bất kỳ xung đột hoặc mâu thuẫn nào giữa Điều khoản Dịch vụ, Chính sách bảo mật, các thỏa thuận sẽ chỉ áp dụng theo thứ tự sau trong phạm vi cần thiết để giải quyết bất kỳ xung đột hoặc mâu thuẫn nào: Điều khoản dịch vụ, Chính sách bảo mật, các thỏa thuận.
              </li>
              <li>Bạn xác nhận và đồng ý rằng Dịch vụ sẽ có thể truy cập và sửa đổi dữ liệu lưu trữ cho Tài khoản IOHUB của bạn, bao gồm thông tin từ các Nền tảng được hỗ trợ. Bạn chịu trách nhiệm cho tất cả các hoạt động xảy ra với Tài khoản IOHUB của mình và bạn đồng ý duy trì tính bảo mật và bí mật của tên người dùng và mật khẩu Tài khoản IOHUB của bạn mọi lúc. Bạn phải thông báo kịp thời cho IOHUB nếu bạn biết hoặc nghi ngờ bất kỳ vi phạm bảo mật nào, bao gồm bất kỳ tổn thất, mất cắp, tiết lộ hoặc sử dụng trái phép Tài khoản IOHUB, tên người dùng hoặc mật khẩu của bạn. IOHUB không thể và sẽ không chịu trách nhiệm cho bất kỳ tổn thất hoặc thiệt hại nào do bạn không duy trì tính bảo mật của Tài khoản IOHUB và/hoặc mật khẩu của bạn.
              </li>
              <li>Nếu Tài khoản IOHUB của bạn bị chấm dứt vì bất kỳ lý do gì, quyền truy cập của bạn vào Dịch vụ cũng sẽ bị chấm dứt.
              </li>
              <li>Nếu bạn đăng ký Dịch vụ thay mặt cho chủ sử dụng lao động của bạn, thì bạn tuyên bố và đảm bảo rằng bạn có thẩm quyền sử dụng Dịch vụ, bao gồm kết nối Tài khoản IOHUB của bạn với Dịch vụ và ràng buộc chủ sử dụng lao động của mình với Điều khoản Dịch vụ của chúng tôi.
              </li>
            </ol>
					</div>
					<div className="doc-para-item">
            <h2>3. Điều khoản chung</h2>
            <ol>
              <li>Dịch vụ hiện chỉ được cung cấp bằng tiếng Anh và tiếng Việt.</li>
              <li>Bạn xác nhận và đồng ý rằng chúng tôi có thể sửa đổi các Điều khoản Dịch vụ này bất cứ lúc nào bằng cách đăng Điều khoản Dịch vụ được sửa đổi và chỉnh sửa có liên quan và các sửa đổi đó đối với Điều khoản Dịch vụ có hiệu lực kể từ ngày đăng. Việc bạn tiếp tục sử dụng Dịch vụ sau khi Điều khoản Dịch vụ sửa đổi được đăng cấu thành việc bạn đồng ý và chấp nhận Điều khoản Dịch vụ sửa đổi. Nếu bạn không đồng ý với bất kỳ thay đổi nào đối với Điều khoản Dịch vụ, đừng tiếp tục sử dụng Dịch vụ.</li>
              <li>Bạn không được sử dụng Dịch vụ cho bất kỳ mục đích bất hợp pháp hoặc trái phép nào cũng như khi sử dụng Dịch vụ, bạn không được vi phạm bất kỳ luật nào trong khu vực pháp lý mà bạn hoạt động.
              </li>
              <li>Bạn không được sử dụng Dịch vụ khi vi phạm các điều khoản và điều kiện áp dụng cho bất kỳ Nền tảng được Hỗ trợ nào mà bạn có thể sử dụng liên quan đến Dịch vụ. Nếu chúng tôi được thông báo về việc bạn vi phạm các điều khoản của Nền tảng được hỗ trợ, chúng tôi có thể, theo quyết định riêng và tuyệt đối của chúng tôi, chấm dứt quyền truy cập của bạn vào Dịch vụ. Để tránh nghi ngờ, IOHUB không chịu trách nhiệm giám sát, thực thi hoặc đảm bảo bạn tuân thủ các điều khoản và điều kiện áp dụng cho Các Nền tảng được hỗ trợ hoặc Các Dịch vụ của Bên thứ ba khác, như được nêu chi tiết hơn trong Mục 5 và 6 dưới đây.
              </li>
              <li>Bạn đồng ý không tái dựng, nhân bản, sao chép, mua bán, hoặc khai thác bất kỳ phần nào của Dịch vụ, việc sử dụng Dịch vụ hoặc truy cập vào Dịch vụ mà không có sự cho phép rõ ràng bằng văn bản của chúng tôi.</li>
              <li>Bạn không được mua công cụ tìm kiếm hoặc từ khóa trả tiền cho mỗi nhấp chuột khác (chẳng hạn như Google Ads), nhãn hiệu hoặc tên miền sử dụng nhãn hiệu của IOHUB và/hoặc biến thể hay lỗi chính tả của chúng.</li>
            </ol>
					</div>
					<div className="doc-para-item">
            <h2>4. Quyền hạn của chúng tôi</h2>
            <ol>
              <li>Chúng tôi có quyền sửa đổi, bao gồm nhưng không giới hạn ở việc thêm hoặc bớt các tính năng, ngừng hoặc chấm dứt Dịch vụ hoặc bất kỳ phần nào trong đó, hoặc chấm dứt Tài khoản IOHUB của bạn hay quyền truy cập của bạn vào Dịch vụ, vì bất kỳ lý do gì mà không cần thông báo bất cứ lúc nào. Chúng tôi sẽ không chịu trách nhiệm với bạn hoặc bất kỳ bên thứ ba nào về bất kỳ sửa đổi, ngừng hoặc chấm dứt Dịch vụ/bất kỳ phần nào của dịch vụ hoặc chấm dứt Tài khoản IOHUB của bạn hay quyền truy cập của bạn vào Dịch vụ.</li>
              <li>Chúng tôi có quyền từ chối Dịch vụ cho bất kỳ ai vì bất kỳ lý do gì bất cứ lúc nào.</li>
              <li>Chúng tôi có thể, nhưng không có nghĩa vụ, xóa mà không cần thông báo bất kỳ Nội dung hoặc Nhận xét của Khách hàng nào (như được định nghĩa trong Mục 10) mà chúng tôi xác định là bất hợp pháp theo quyết định riêng của chúng tôi như xúc phạm, đe dọa, bôi nhọ, phỉ báng, khiêu dâm, tục tĩu, phản cảm, vi phạm tài sản trí tuệ của bất kỳ bên nào hoặc các Điều khoản Dịch vụ này.</li>
              <li>Việc vi phạm bất kỳ điều khoản nào của Điều khoản Dịch vụ, được xác định theo quyết định riêng của chúng tôi, có thể dẫn đến việc chấm dứt ngay lập tức quyền truy cập của bạn vào Dịch vụ. Không giới hạn bất kỳ biện pháp khắc phục nào khác mà chúng tôi có, chúng tôi có thể đình chỉ hoặc chấm dứt quyền truy cập của bạn vào Dịch vụ nếu chúng tôi nghi ngờ rằng bạn đã tham gia vào hoạt động gian lận liên quan đến Dịch vụ.</li>
              <li>Việc lạm dụng bằng lời nói hoặc bằng văn bản dưới bất kỳ hình thức nào (bao gồm cả các mối đe dọa lạm dụng hoặc trả thù) đối với bất kỳ nhân viên, thành viên IOHUB nào sẽ dẫn đến việc chấm dứt dịch vụ ngay lập tức.</li>
              <li>Chúng tôi có quyền, nhưng không bắt buộc, để giới hạn tính khả dụng của Dịch vụ, hoặc các sản phẩm, dịch vụ được cung cấp thông qua Dịch vụ, cho bất kỳ người nào, khu vực địa lý, phạm vi pháp lý nào. Chúng tôi có thể thực hiện quyền này trong từng trường hợp cụ thể.</li>
              <li>Chúng tôi có quyền cung cấp Dịch vụ của chúng tôi cho các đối thủ cạnh tranh của bạn và không hứa hẹn độc quyền trong bất kỳ phân khúc thị trường cụ thể nào. Bạn cũng phải chấp nhận và đồng ý rằng nhân viên và nhà thầu của IOHUB cũng có thể sử dụng Dịch vụ và họ có thể cạnh tranh với bạn, mặc dù họ có thể không sử dụng thông tin bí mật của bạn khi làm như vậy.</li>
            </ol>
					</div>
					<div className="doc-para-item">
            <h2>5. Nền tảng được hỗ trợ</h2>
            <ol>
              <li>Để Dịch vụ truy cập các Nền tảng được hỗ trợ, bạn phải cài đặt ứng dụng của Nền tảng được hỗ trợ trên Tài khoản IOHUB của mình. Các ứng dụng Nền tảng được hỗ trợ có sẵn trong IOHUB Workflow. Nếu ứng dụng được cài đặt trên Tài khoản IOHUB của bạn, bạn sẽ tự động có quyền truy cập vào nó thông qua Dịch vụ.</li>
              <li>Bạn chấp nhận rằng Dịch vụ tương tác với một số Nền tảng được hỗ trợ và Dịch vụ này phụ thuộc nhiều vào tính khả dụng của các Nền tảng được hỗ trợ đó. Nếu bất cứ lúc nào bất kỳ Nền tảng được hỗ trợ nào không khả dụng hoặc ngừng cung cấp dịch vụ của họ cho IOHUB với các điều khoản hợp lý, IOHUB có thể ngừng cung cấp các tính năng đó cho bạn mà không cần thông báo và không cho bạn hoàn lại tiền, tín dụng hoặc các khoản bồi thường khác.</li>
              <li>Bạn hiểu rằng bằng cách sử dụng Dịch vụ, bạn có thể tiếp xúc với nội dung hoặc thông tin của bên thứ ba có thể bất hợp pháp, xúc phạm, có hại, không chính xác hoặc thậm chí bị phản đối. IOHUB không sở hữu, kiểm soát hoặc xem xét nội dung này và IOHUB không có nghĩa vụ phải xem trước, xác minh, gắn cờ, sửa đổi, lọc hoặc xóa bất kỳ nội dung nào của bên thứ ba, ngay cả khi được yêu cầu, mặc dù IOHUB có thể làm như vậy theo quyết định riêng của mình. Việc bạn sử dụng Nền tảng được hỗ trợ do bạn tự chịu rủi ro và IOHUB sẽ không chịu trách nhiệm với bạn hoặc bất kỳ bên thứ ba nào liên quan đến nội dung của bên thứ ba có thể được truy cập thông qua Dịch vụ.</li>
            </ol>
					</div>
					<div className="doc-para-item">
            <h2>6. Dịch vụ của Bên thứ ba</h2>
            <ol>
              <li>Bạn chấp nhận rằng Dịch vụ có thể cho phép hoặc hỗ trợ bạn truy cập, tương tác và/hoặc mua dịch vụ từ Các Nền tảng được Hỗ trợ và các bên thứ ba khác thông qua phần mềm, ứng dụng, sản phẩm, dịch vụ hoặc liên kết trang web của bên thứ ba (gọi chung là "Dịch vụ của bên thứ ba") cho sự cân nhắc hoặc sử dụng của bạn. Dịch vụ của bên thứ ba chỉ được cung cấp để tạo sự thuận tiện và việc bạn mua, truy cập hoặc sử dụng bất kỳ Dịch vụ của bên thứ ba nào như vậy chỉ là giữa bạn và nhà cung cấp Dịch vụ bên thứ ba hiện hành ("Nhà cung cấp bên thứ ba"). Bất kỳ việc sử dụng nào của bạn đối với Dịch vụ Bên thứ ba được cung cấp thông qua Dịch vụ đều hoàn toàn do bạn tự chịu rủi ro và toàn quyền quyết định. Ngoài các Điều khoản dịch vụ này, bạn phải đọc và đồng ý với các điều khoản bổ sung dành riêng cho dịch vụ và/hoặc chính sách bảo mật áp dụng cho Dịch vụ của bên thứ ba đó trước khi bạn sử dụng chúng cùng với Dịch vụ.</li>
              <li>Chúng tôi không cung cấp bất kỳ bảo đảm nào đối với Dịch vụ của Bên thứ ba. Bạn chấp nhận rằng IOHUB không có quyền kiểm soát Dịch vụ của Bên thứ ba và sẽ không chịu trách nhiệm hoặc chịu nghĩa vụ pháp lý với bất kỳ ai về Các Dịch vụ của Bên thứ ba đó. Bất kỳ vấn đề nào gây ra bởi hoặc liên quan đến Dịch vụ của Bên thứ ba cần phải được đưa đến Nhà cung cấp Dịch vụ Bên thứ ba hiện hành và sẽ không được IOHUB xử lý. Tính khả dụng của Dịch vụ bên thứ ba trên trang web, trong IOHUB Workflow hoặc việc cho phép sử dụng Dịch vụ của bên thứ ba đó với Dịch vụ không cấu thành hoặc ngụ ý chấp nhận, ủy quyền, tài trợ hoặc liên kết bởi/với IOHUB. IOHUB đặc biệt khuyến nghị bạn nên nhờ chuyên gia tư vấn trước khi sử dụng hoặc dựa vào Dịch vụ của bên thứ ba để đảm bảo chúng sẽ đáp ứng được nhu cầu của bạn.</li>
              <li>IOHUB không chịu trách nhiệm đối với bất kỳ quảng cáo, bài đăng trên mạng xã hội hoặc tin nhắn nào bị từ chối hoặc hủy gửi bởi Dịch vụ bên thứ ba và IOHUB không chịu trách nhiệm quản lý các tùy chọn cài đặt hủy đăng ký. Tất cả các quy trình quảng cáo và cài đặt hủy đăng ký đều do Dịch vụ bên thứ ba quản lý và hoàn toàn theo quyết định của Dịch vụ bên thứ ba và IOHUB không hề biết hoặc kiểm soát các quy trình đó. Mọi câu hỏi liên quan đến Dịch vụ của Bên thứ ba, bao gồm quảng cáo bị từ chối hoặc chưa được phân phối hoặc cài đặt hủy đăng ký, phải được chuyển đến Nhà cung cấp bên thứ ba.
              </li>
              <li>Nếu bạn cài đặt hoặc bật Dịch vụ của Bên thứ ba để sử dụng với Dịch vụ, bạn cấp cho chúng tôi quyền cho phép Nhà cung cấp bên thứ ba hiện hành truy cập dữ liệu của bạn và thực hiện bất kỳ hành động nào khác theo yêu cầu đối với việc tương tác dịch vụ của bên thứ ba với Dịch vụ và mọi trao đổi dữ liệu hoặc tương tác khác giữa bạn và Nhà cung cấp bên thứ ba đó chỉ là giữa bạn và Nhà cung cấp bên thứ ba đó. IOHUB không chịu trách nhiệm về bất kỳ việc tiết lộ, sửa đổi hay xóa dữ liệu hoặc Nội dung khách hàng của bạn hoặc đối với bất kỳ tổn thất, thiệt hại tương ứng nào bạn có thể phải chịu, do quyền truy cập của Dịch vụ bên thứ ba hoặc Nhà cung cấp bên thứ ba vào dữ liệu hoặc Nội dung khách hàng của bạn.</li>
              <li>Trong mọi trường hợp, IOHUB sẽ không chịu trách nhiệm pháp lý cho bất kỳ thiệt hại trực tiếp, gián tiếp, ngẫu nhiên, đặc biệt, mang tính kết quả, bất thường, bồi thường thiệt hại theo hình thức trừng phạt, hoặc các thiệt hại khác, phát sinh từ bất kỳ Dịch vụ bên thứ ba nào hoặc mối quan hệ hợp đồng của bạn với bất kỳ Nhà cung cấp bên thứ ba nào. Những hạn chế này sẽ được áp dụng ngay cả khi IOHUB đã được thông báo về khả năng xảy ra những thiệt hại đó. Các giới hạn nói trên sẽ được áp dụng trong phạm vi tối đa mà luật hiện hành cho phép.</li>
              <li>Bạn đồng ý bồi thường và giữ cho chúng tôi và (nếu có) công ty mẹ, công ty con, chi nhánh, đối tác IOHUB, cán bộ, giám đốc, đại lý, nhân viên và nhà cung cấp của chúng tôi vô hại trước bất kỳ khiếu nại hoặc yêu cầu nào, bao gồm chi phí luật sư hợp lý, phát sinh từ việc bạn sử dụng Dịch vụ của Bên thứ ba hoặc mối quan hệ của bạn với Nhà cung cấp bên thứ ba.</li>
              <li>Để rõ ràng, các ứng dụng được phát triển, hỗ trợ và duy trì bởi IOHUB và theo các mục đích của Thỏa thuận này, IOHUB được coi là Nhà cung cấp bên thứ ba của các ứng dụng đó.</li>
            </ol>
					</div>
					<div className="doc-para-item">
            <h2>7. Sở hữu trí tuệ</h2>
            <ol>
              <li>Bạn cấp cho IOHUB một giấy phép có giới hạn, trên toàn thế giới, không độc quyền, không có quyền cấp giấy phép phụ, để truy cập, sử dụng, tái sản xuất, phân phối điện tử, truyền tải, thực hiện, định dạng, hiển thị, lưu trữ, và lập danh mục Nội dung khách hàng với mục đích hỗ trợ bạn sử dụng Dịch vụ và cung cấp Dịch vụ cho bạn. IOHUB cũng có thể sử dụng Nội dung khách hàng cho mục đích hỗ trợ và phát triển trang web hoặc các ứng dụng IOHUB, với điều kiện là khi làm như vậy, IOHUB sẽ chỉ được sử dụng Nội dung khách hàng một cách ẩn danh và tổng hợp. Chỉ tuân theo giấy phép giới hạn được cấp rõ ràng ở đây, bạn sẽ giữ lại tất cả các quyền, quyền sở hữu và lợi ích đối với Nội dung khách hàng và tất cả các quyền sở hữu trí tuệ trong đó. Không có nội dung nào trong Thỏa thuận này sẽ trao cho IOHUB bất kỳ quyền sở hữu hoặc lợi ích nào đối với Nội dung của khách hàng.</li>
              <li>Bạn hoàn toàn chịu trách nhiệm về Nội dung khách hàng mà bạn tải lên, xuất bản, hiển thị, liên kết đến hoặc cung cấp thông qua Dịch vụ và bạn đồng ý rằng IOHUB chỉ cho phép phân phối và xuất bản trực tuyến Nội dung Khách hàng. IOHUB sẽ không xem xét, chia sẻ, phân phối hoặc tham khảo bất kỳ Nội dung khách hàng nào trừ khi được cung cấp ở đây, như được quy định trong Chính sách bảo mật của IOHUB hoặc theo yêu cầu của pháp luật. Bất kể những điều đã nói ở trên, IOHUB vẫn có quyền xóa bất kỳ Nội dung Khách hàng nào được tải lên mà chúng tôi cho là vi phạm các Điều khoản Dịch vụ này, theo quyết định riêng của mình.</li>
              <li>Bạn chấp nhận và đồng ý rằng Dịch vụ, bao gồm nhưng không giới hạn, bất kỳ phần mềm, tài liệu, thiết kế, ứng dụng, trang web, công cụ và sản phẩm liên quan nào, và bất kỳ sửa đổi, cải tiến và cập nhật nào trong đó, tất cả các quyền sở hữu trí tuệ trong đó chỉ thuộc sở hữu của IOHUB.</li>
            </ol>
					</div>
					<div className="doc-para-item">
            <h2>8. Bảo mật và bảo vệ dữ liệu</h2>
            <ol>
              <li>IOHUB cam kết bảo vệ quyền riêng tư của thông tin cá nhân của bạn và thông tin cá nhân của khách hàng của bạn. Bằng việc sử dụng Dịch vụ, bạn chấp nhận và đồng ý rằng việc IOHUB thu thập, sử dụng và tiết lộ thông tin cá nhân này được tuân theo Chính sách bảo mật của chúng tôi.</li>
              <li>Ngoài ra, nếu bạn không ở Việt Nam, bạn phải tuân thủ luật pháp của khu vực hoặc quốc gia của bạn.</li>
            </ol>
					</div>
					<div className="doc-para-item">
            <h2>9. Phí sử dụng</h2>
            <ol>
              <li>Bạn có thể thanh toán phí dịch vụ cho IOHUB bằng tiền mặt hoặc chuyển trực tiếp cho chúng tôi hoặc thông qua cổng thanh toán tại IOHUB. Sau khi thanh toán, hệ thống sẽ gửi hóa đơn trực tuyến đến email của bạn (mà bạn đã cung cấp khi đăng ký). Hệ thống sẽ gửi thông báo về thời hạn thanh toán đến email của bạn trước 3 ngày (hoặc hơn). Nếu bạn không trả phí đúng hạn, bạn sẽ có 15 ngày sử dụng và sau 15 ngày, tài khoản của bạn sẽ bị giới hạn về chức năng. Sau 6 tháng, tài khoản của bạn có thể tự động bị hủy.</li>
              <li>Nếu bạn không ở Việt Nam, bạn phải tuân thủ luật pháp và thuế của khu vực hoặc quốc gia của bạn.</li>
              <li>Chúng tôi có thể thay đổi phí của mình bất cứ lúc nào bằng cách đăng bảng giá mới lên trang web của chúng tôi và/hoặc gửi thông báo cho bạn qua email.</li>
              <li>Chúng tôi không hỗ trợ hoàn tiền.
              </li>
            </ol>
					</div>
					<div className="doc-para-item">
            <h2>10. Nhận xét, phản hồi của người dùng, và các phản hồi khác</h2>
            <ol>
              <li>Nếu bạn gửi bất kỳ ý tưởng, đề xuất, nhận xét, kế hoạch hoặc nội dung, đệ trình khác cho chúng tôi, cho dù trực tuyến, thông qua phương tiện truyền thông xã hội, qua email hoặc bằng phương thức khác (gọi chung là "Nhận xét"), bạn đồng ý rằng chúng tôi có thể, bất cứ lúc nào, mà không bị hạn chế, chỉnh sửa, sao chép, xuất bản, phân phối, dịch và sử dụng trong bất kỳ phương tiện nào nhận xét bất kỳ mà bạn chuyển tiếp cho chúng tôi. Chúng tôi không có nghĩa vụ 
                <ul>
                  <li><i>(a)</i> sử dụng hoặc dựa vào bất kỳ Nhận xét nào;</li>
                  <li><i>(b)</i> duy trì bất kỳ Nhận xét nào một cách bảo mật (trừ khi những bình luận đó chứa Thông tin Cá nhân (như được định nghĩa trong Chính sách Bảo mật)); </li>
                  <li><i>(c)</i> bồi thường cho bất kỳ Bình luận nào; hoặc </li>
                  <li><i>(d)</i> trả lời bất kỳ Bình luận nào. </li>
                </ul>
              </li>
              <li>Bạn đồng ý rằng Nhận xét của bạn sẽ không vi phạm bất kỳ quyền của bất kỳ bên thứ ba nào, bao gồm bản quyền, nhãn hiệu, quyền riêng tư, nhân cách hoặc quyền khác, sở hữu trí tuệ, quyền cá nhân hoặc quyền sở hữu. Bạn cũng đồng ý rằng Nhận xét của bạn sẽ không chứa tài liệu bôi nhọ hoặc bất hợp pháp, lạm dụng, thù hận hoặc tục tĩu hoặc chứa bất kỳ vi-rút máy tính hoặc phần mềm độc hại nào khác có thể ảnh hưởng đến hoạt động của Dịch vụ hoặc bất kỳ trang web hoặc ứng dụng liên quan nào. Bạn không được sử dụng địa chỉ email giả, giả vờ là người khác không phải chính bạn hoặc đánh lừa chúng tôi hoặc bên thứ ba về nguồn gốc của bất kỳ Nhận xét nào. Bạn hoàn toàn chịu trách nhiệm về bất kỳ Nhận xét nào bạn đưa ra và tính chính xác của chúng. Chúng tôi không chịu trách nhiệm và cũng không chịu trách nhiệm pháp lý đối với bất kỳ Bình luận nào được đăng bởi bạn hoặc bên thứ ba nào.</li>
            </ol>
					</div>
					<div className="doc-para-item">
            <h2>11. Các cách sử dụng bị cấm</h2>
            <ol>
              <li>Ngoài các điều cấm khác như được quy định trong Điều khoản Dịch vụ, bạn bị cấm gửi Nội dung khách hàng hoặc sử dụng Dịch vụ: 
                <ul>
                  <li><i>(a)</i> nhằm vi phạm bất kỳ luật, quy định, quy tắc hoặc pháp lệnh quốc tế, liên bang hoặc địa phương nào; </li>
                  <li><i>(b)</i> xâm phạm hoặc vi phạm quyền sở hữu trí tuệ của chúng tôi hoặc quyền sở hữu trí tuệ của người khác; </li>
                  <li><i>(c)</i> tải lên hoặc truyền tải từ Dịch vụ bất kỳ dữ liệu, tệp, phần mềm hoặc liên kết nào có chứa hoặc chuyển hướng đến vi-rút, Trojan horse, sâu máy tính hoặc thành phần có hại khác; </li>
                  <li><i>(d)</i> quấy rối, lạm dụng, lăng mạ, làm hại, phỉ báng, vu khống, miệt thị, đe dọa hoặc phân biệt đối xử dựa trên giới tính, khuynh hướng tình dục, tôn giáo, dân tộc, chủng tộc, tuổi tác, nguồn gốc quốc gia hoặc khuyết tật;</li> 
                  <li><i>(e)</i> gửi thông tin sai lệch hoặc gây hiểu lầm; </li>
                  <li><i>(f)</i> để tải lên hoặc truyền vi-rút hoặc bất kỳ loại mã độc hại hoặc phá hoại nào khác; </li>
                  <li><i>(g)</i> thu thập hoặc theo dõi thông tin cá nhân của người khác; </li>
                  <li><i>(h)</i> để gửi thư rác, tấn công mạng như: phishing, pharming, pretext, spider, crawl hoặc scrape; </li>
                  <li><i>(i)</i> cho bất kỳ mục đích tục tĩu hoặc vô đạo đức nào; </li>
                  <li><i>(j)</i> can thiệp hoặc phá vỡ các tính năng bảo mật của Dịch vụ hoặc bất kỳ trang web, ứng dụng liên quan nào, hoặc trang web, ứng dụng khác hoặc Internet; </li>
                  <li><i>(k)</i> cố gắng đảo ngược thiết kế, đảo ngược biên dịch, hack, vô hiệu hóa, can thiệp, tháo rời, sao chép hoặc phá vỡ tính toàn vẹn hoặc hiệu suất của Dịch vụ; hoặc </li>
                  <li><i>(l)</i> truy cập Dịch vụ nhằm xây dựng một sản phẩm hoặc dịch vụ cạnh tranh hoặc sao chép bất kỳ ý tưởng, tính năng, chức năng hoặc đồ họa nào của Dịch vụ.</li>
                  <li><i>(m)</i> Bán, cấp phép hoặc mua Dữ liệu trên nền tảng</li>
                </ul>
              </li>
            </ol>
					</div>
					<div className="doc-para-item">
            <h2>12. Tuyên bố từ chối bảo hành; Giới hạn trách nhiệm pháp lý</h2>
            <ol>
              <li>BẠN HIỂU RÕ VÀ ĐỒNG Ý RẰNG IOHUB SẼ KHÔNG CHỊU TRÁCH NHIỆM CHO BẤT KỲ THIỆT HẠI TRỰC TIẾP, GIÁN TIẾP, NGẪU NHIÊN, ĐẶC BIỆT, MANG TÍNH KẾT QUẢ HOẶC BỒI THƯỜNG THIỆT HẠI THEO HÌNH THỨC TRỪNG PHẠT NÀO, BAO GỒM NHƯNG KHÔNG GIỚI HẠN, THIỆT HẠI CHO TỔN THẤT LỢI NHUẬN, TÍN NHIỆM, VIỆC SỬ DỤNG, DỮ LIỆU HOẶC TỔN THẤT VÔ HÌNH KHÁC GÂY RA TỪ SỬ DỤNG HOẶC KHÔNG CÓ KHẢ NĂNG SỬ DỤNG DỊCH VỤ. BỞI VÌ MỘT SỐ KHU VỰC PHÁP LÝ KHÔNG CHO PHÉP LOẠI TRỪ HOẶC GIỚI HẠN TRÁCH NHIỆM PHÁP LÝ ĐỐI VỚI CÁC THIỆT HẠI MANG TÍNH KẾT QUẢ HOẶC PHÁT SINH THÊM, TRONG CÁC KHU VỰC PHÁP LÝ ĐÓ, TRÁCH NHIỆM PHÁP LÝ CỦA CHÚNG TÔI SẼ ĐƯỢC GIỚI HẠN TRONG PHẠM VI TỐI ĐA ĐƯỢC PHÁP LUẬT CHO PHÉP.</li>
              <li>BẠN ĐỒNG Ý BỒI THƯỜNG, BẢO VỆ VÀ GIỮ IOHUB, CÁC CHI NHÁNH, CÁN BỘ, GIÁM ĐỐC, NHÂN VIÊN, ĐẠI LÝ VÀ NGƯỜI CẤP PHÉP CỦA NÓ KHÔNG BỊ THIỆT HẠI KHỎI TẤT CẢ CÁC KHIẾU NẠI, TỔN THẤT, THIỆT HẠI, HÌNH PHẠT, TRÁCH NHIỆM PHÁP LÝ VÀ CHI PHÍ, BAO GỒM CHI PHÍ LUẬT SƯ, DƯỚI BẤT KỲ HÌNH THỨC HOẶC BẢN CHẤT NÀO LIÊN QUAN ĐẾN KHIẾU NẠI:
                <ul>
                  <li><i>(A)</i> CÁO BUỘC RẰNG NỘI DUNG KHÁCH HÀNG HOẶC VIỆC BẠN SỬ DỤNG DỊCH VỤ XÂM PHẠM HOẶC VI PHẠM QUYỀN SỞ HỮU TRÍ TUỆ , QUYỀN RIÊNG TƯ HOẶC CÁC QUYỀN KHÁC CỦA BÊN THỨ BA HOẶC VI PHẠM LUẬT HIỆN HÀNH; </li>
                  <li><i>(B)</i> PHÁT SINH TỪ VIỆC BẠN VI PHẠM CÁC ĐIỀU KHOẢN DỊCH VỤ NÀY HOẶC CÁC TÀI LIỆU ĐƯỢC ĐỀ CẬP THAM CHIẾU; </li>
                  <li><i>(C)</i> PHÁT SINH TỪ VIỆC BẠN SỬ DỤNG DỊCH VỤ CỦA BÊN THỨ BA HOẶC MỐI QUAN HỆ CỦA BẠN VỚI NHÀ CUNG CẤP BÊN THỨ BA; HOẶC </li>
                  <li><i>(D)</i> CÁO BUỘC RẰNG BẤT KỲ THÔNG TIN NÀO ĐƯỢC TẠO ĐIỀU KIỆN TRUYỀN TẢI THÔNG QUA DỊCH VỤ VI PHẠM LUẬT HIỆN HÀNH.</li>
                </ul>
              </li>
              <li>VIỆC BẠN SỬ DỤNG DỊCH VỤ LÀ RỦI RO DUY NHẤT CỦA BẠN. DỊCH VỤ ĐƯỢC CUNG CẤP TRÊN CƠ SỞ "NGUYÊN TRẠNG" VÀ "CÓ SẴN" MÀ KHÔNG CÓ BẤT KỲ BẢO HÀNH HOẶC ĐIỀU KIỆN NÀO, RÕ RÀNG, NGỤ Ý HOẶC THEO LUẬT ĐỊNH.
              </li>
              <li>IOHUB KHÔNG ĐẢM BẢO RẰNG 
                <ul>
                  <li><i>(A)</i> DỊCH VỤ SẼ KHÔNG BỊ GIÁN ĐOẠN, KỊP THỜI, AN TOÀN HOẶC KHÔNG CÓ LỖI;</li> 
                  <li><i>(B)</i> KẾT QUẢ CÓ THỂ THU ĐƯỢC TỪ VIỆC SỬ DỤNG DỊCH VỤ SẼ CHÍNH XÁC HOẶC ĐÁNG TIN CẬY; HOẶC </li>
                  <li><i>(C)</i> CHẤT LƯỢNG CỦA BẤT KỲ SẢN PHẨM, DỊCH VỤ, THÔNG TIN HOẶC TÀI LIỆU NÀO KHÁC MÀ BẠN MUA HOẶC THU ĐƯỢC THÔNG QUA DỊCH VỤ SẼ ĐÁP ỨNG MONG ĐỢI CỦA BẠN HOẶC BẤT KỲ LỖI NÀO TRONG DỊCH VỤ SẼ ĐƯỢC SỬA CHỮA.
                  </li>
                </ul>
              </li>
              <li>BẤT KỲ SỰ PHỤ THUỘC NÀO VÀO TÀI LIỆU HOẶC THÔNG TIN CÓ ĐƯỢC THÔNG QUA DỊCH VỤ ĐỀU LÀ RỦI RO CỦA RIÊNG BẠN.</li>
              <li>ĐÔI KHI THÔNG TIN CÓ SẴN TRONG DỊCH VỤ CÓ THỂ CÓ LỖI ĐÁNH MÁY, KHÔNG CHÍNH XÁC HOẶC THIẾU SÓT CÓ THỂ LIÊN QUAN ĐẾN MÔ TẢ SẢN PHẨM, GIÁ CẢ, KHUYẾN MÃI, ƯU ĐÃI, PHÍ VẬN CHUYỂN SẢN PHẨM, THỜI GIAN VẬN CHUYỂN VÀ TÌNH TRẠNG TỒN KHO.
              </li>
              <li>CHÚNG TÔI KHÔNG CÓ NGHĨA VỤ CẬP NHẬT, SỬA ĐỔI HOẶC LÀM RÕ THÔNG TIN TRONG DỊCH VỤ, BAO GỒM NHƯNG KHÔNG GIỚI HẠN, THÔNG TIN GIÁ CẢ, TRỪ KHI PHÁP LUẬT YÊU CẦU. KHÔNG CÓ NGÀY CẬP NHẬT MỚI CỤ THỂ NÀO ĐƯỢC ÁP DỤNG TRONG DỊCH VỤ, THỂ HIỆN RẰNG TẤT CẢ THÔNG TIN TRONG DỊCH VỤ ĐÃ ĐƯỢC SỬA ĐỔI HOẶC CẬP NHẬT.
              </li>
            </ol>
					</div>
					<div className="doc-para-item">
            <h2>13. Luật điều chỉnh</h2>
            <p>Các Điều khoản Dịch vụ này và bất kỳ thỏa thuận riêng biệt nào mà theo đó chúng tôi cung cấp Dịch vụ cho bạn sẽ được điều chỉnh bởi và hướng dẫn theo luật pháp Việt Nam. Địa chỉ gửi thư của IOHUB là 41 Trung Lương 8, Phường Hòa Xuân, Quận Cẩm Lệ, Thành phố Đà Nẵng, Việt Nam.
            </p>
					</div>
					<div className="doc-para-item">
            <h2>14. Tính nghiêm trọng </h2>
            <p>Trong trường hợp bất kỳ điều khoản nào trong các Điều khoản Dịch vụ này được tòa án có thẩm quyền xác định là trái pháp luật, vô hiệu hoặc không thể thi hành, thì điều khoản đó vẫn sẽ có hiệu lực thi hành ở mức tối đa mà luật hiện hành cho phép, phần không thể thi hành sẽ được coi là bị cắt khỏi các Điều khoản dịch vụ này và quyết định đó sẽ không ảnh hưởng đến hiệu lực và khả năng thực thi của bất kỳ điều khoản nào còn lại.
            </p>
					</div>
					<div className="doc-para-item">
            <h2>15. Chấm dứt thỏa thuận</h2>
            <p>Các Điều khoản Dịch vụ này có hiệu lực trừ khi và cho đến khi bị chấm dứt bởi bạn hoặc bởi chúng tôi. Nếu bạn không muốn sử dụng Dịch vụ nữa, bạn có thể chấm dứt Tài khoản IOHUB của mình theo các điều khoản hiện hành. Nếu theo quyết định riêng của chúng tôi bạn không thực hiện được, hoặc chúng tôi nghi ngờ rằng bạn đã không tuân thủ bất kỳ điều khoản hoặc quy định nào của Điều khoản dịch vụ này, chúng tôi cũng có thể chấm dứt Điều khoản dịch vụ này bất kỳ lúc nào mà không cần thông báo và do đó có thể từ chối cho phép bạn truy cập vào Dịch vụ, Tài khoản IOHUB của bạn hoặc bất kỳ phần nào trong đó. Các nghĩa vụ (bao gồm nghĩa vụ thanh toán, nếu có) và các khoản nợ phát sinh trước ngày chấm dứt dịch vụ sẽ vẫn tồn tại sau khi chấm dứt các Điều khoản Dịch vụ này cho tất cả các mục đích.
						</p>
					</div>
					<div className="doc-para-item">
            <h2>16. Từ bỏ quyền và Thỏa thuận Toàn Bộ</h2>
            <ol>
              <li>Việc chúng tôi không thực hiện hoặc thực thi bất kỳ quyền hoặc quy định nào trong các Điều khoản Dịch vụ này sẽ không cấu thành việc từ bỏ quyền hoặc điều khoản đó. </li>
              <li>Các Điều khoản Dịch vụ này và bất kỳ chính sách hoặc quy tắc hoạt động nào được tích hợp trong các Điều khoản Dịch vụ này hoặc được chúng tôi đăng tải liên quan đến Dịch vụ cấu thành toàn bộ thỏa thuận và hiểu biết giữa bạn và chúng tôi, đồng thời chi phối việc bạn sử dụng Dịch vụ, thay thế mọi thỏa thuận, trao đổi và đề xuất trước đây hoặc cùng thời điểm, cho dù bằng miệng hay bằng văn bản, giữa bạn và chúng tôi (bao gồm nhưng không giới hạn ở bất kỳ phiên bản trước nào của Điều khoản Dịch vụ).</li>
            </ol>
					</div>
        </div>
      </section>
    </>
  );
};

export default TermsOfServiceVI;
