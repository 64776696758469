import React from 'react';
import Divider from './Divider';
import {useTranslation} from 'gatsby-plugin-react-i18next';

const TermsOfServiceEN = () => {
  const {t} = useTranslation();
  return(
    <>
      <section className="text-gray-600 bg-gray-100 body-font pt-10">
        <div className="my-cont doc-para">
          <Divider title={t('sub.title')} />
          <p>The following terms (“Terms of Service”) describe the terms and conditions applicable to your access to and use of the IOHUB application, services, and any associated software and products (collectively referred to as the “Service”). This document is a legally binding agreement between you and IOHUB Tech Company Limited and our Affiliates (referred to as “we”, “our”, “us” or “IOHUB”), where “Affiliates” means any entity that directly or indirectly controls, is controlled by, or is under common control with IOHUB Tech Company Limited. In order to access and use the Services, you must have an active IOHUB online account (“IOHUB Account”). Where we write “you” or “your”, we mean the owner of the IOHUB Account registering for the Service.</p>
          <p>These terms and conditions apply to all the Service, includes but not limited to: IOHUB Workflow, and any associated applications that are owned and operated by IOHUB or one of our affiliated companies. By signing up for the Service you are agreeing to be bound by the Terms of Service. Any new features or tools which are added to the current Service shall also be subject to the Terms of Service. You can review the current version of the Terms of Service at any time here: <a href="https://iohub.tech/termsofservice">https://iohub.tech/termsofservice</a>. We reserve the right to update and change the Terms of Service by posting updates and changes. You are advised to check the Terms of Service from time to time for any updates or changes that may impact you.</p>
          <p>You must read, agree with and accept all of the terms and conditions contained in this Terms of Service agreement and our Privacy Policy before you may use the Service.</p>
        </div>
      </section>
      <section className="text-gray-600 body-font">
        <div className="my-cont doc-para">
					<div className="doc-para-item">
            <h2>1. Definitions</h2>
            <ol>
              <li><strong>Customer Content</strong> means all information and data (including text, customer conversations, Staff conversations, images, photos, videos, audio and documents) or any other content in any media or format provided or made available by you or on your behalf in relation to your use of the Service, including information and data available in your IOHUB Account, which may include information or data from a Supported Platform installed on your IOHUB Account.</li>
              <li><strong>IOHUB Workflow</strong> means the IOHUB website where you can select and install the applications on your IOHUB Account, available at: <a href="https://app.iohub.tech">https://app.iohub.tech</a></li>
              <li><strong>Staff</strong> means one or more staff accounts that a Account Owner (as defined in the IOHUB Terms of service) can create to allow other people to access the IOHUB Account.</li>
              <li><strong>Supported Platform(s)</strong> means the third party software, connectors, applications, products, or services currently supported by the Service that may be available in the IOHUB Workflow, including IOHUB as described in more detail below and on the IOHUB website, available at <a href="https://iohub.tech">https://iohub.tech</a> (the “Site”)</li>
						</ol>
					</div>
					<div className="doc-para-item">
            <h2>2. IOHUB Account</h2>
            <ol>
              <li>To access and use the Service, you must login using your IOHUB Account credentials, including your email address and any other information identified as being required. You agree to maintain accurate, complete, and up‑to‑date information for your IOHUB Account. Your failure to maintain accurate, complete, and up‑to‑date IOHUB Account information may result in the termination of your IOHUB Account and/or your inability to access and use the Service.</li>
              <li>As a user of a IOHUB Account, you are also subject to the Terms of Service, Privacy Policy, and any other applicable terms. If there is any conflict or inconsistency between the Terms of Service, Privacy Policy, the agreements will govern in the following order only to the extent necessary to resolve any conflict or inconsistency: Terms of Service, Privacy Policy, the agreements.</li>
              <li>You acknowledge and agree that the Service will be able to access and modify the stored data for your IOHUB Account, including information from Supported Platforms. You are responsible for all activity that occurs under your IOHUB Account, and you agree to maintain the security and secrecy of your IOHUB Account username and password at all times. You must promptly notify IOHUB if you become aware of or reasonably suspect any security breach, including any loss, theft or unauthorized disclosure or use of your IOHUB Account, username or password. IOHUB cannot and will not be liable for any loss or damage from your failure to maintain the security of your IOHUB Account and/or password.</li>
              <li>If your IOHUB Account is terminated for any reason, your access to the Service will also be terminated.
              </li>
              <li>If you are signing up for the Service on behalf of your employer, then you represent and warrant that you have the authority to use the Service, including connecting your IOHUB Account to the Service, and bind your employer to our Terms of Service.</li>
						</ol>
					</div>
					<div className="doc-para-item">
            <h2>3. General Conditions</h2>
            <ol>
              <li>The Service is currently only offered in the English &amp; Vietnamese language.</li>
              <li>You acknowledge and agree that we may amend these Terms of Service at any time by posting the relevant amended and restated Terms of Service, and such amendments to the Terms of Service are effective as of the date of posting. Your continued use of the Service after the amended Terms of Service are posted constitutes your agreement to, and acceptance of, the amended Terms of Service. If you do not agree to any changes to the Terms of Service, do not continue to use the Service.</li>
              <li>You may not use the Service for any illegal or unauthorized purpose nor may you, in using the Service, violate any laws in the jurisdictions in which you operate.
              </li>
              <li>You may not use the Service in violation of the terms and conditions applicable to any Supported Platforms that you may use in connection with the Service. If we are advised of your violation of Supported Platform terms, we may, in our sole and absolute discretion, terminate your access to the Services. For the avoidance of doubt, IOHUB is not responsible for monitoring, enforcing or ensuring your compliance with the terms and conditions applicable to Supported Platforms or other Third Party Services, as outlined in more detail in Sections 5 and 6 below.</li>
              <li>You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service without our express written permission.</li>
              <li>You shall not purchase search engine or other pay per click keywords (such as Google Ads), trademarks or domain names that use IOHUB's trademarks and/or variations and misspellings thereof.
              </li>
						</ol>
					</div>
					<div className="doc-para-item">
            <h2>4. Our Rights</h2>
            <ol>
              <li>We reserve the right to modify, including but not limited to adding or removing features, discontinue or terminate the Service or any part thereof, or terminate your IOHUB Account or your access to the Service, for any reason without notice at any time. We shall not be liable to you or to any third party for any modification, discontinuance or termination of the Service or any part thereof, or the termination of your IOHUB Account or your access to the Service.</li>
              <li>We reserve the right to refuse the Service to anyone for any reason at any time.</li>
              <li>We may, but have no obligation to, remove without notice any Customer Content or Comments (as defined in Section 10) that we determine in our sole discretion are unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable or violate any party’s intellectual property or these Terms of Service.</li>
              <li>A breach or violation of any provision of the Terms of Service, as determined in our sole discretion, may result in an immediate termination of your access to the Service. Without limiting any other remedies that we have, we may suspend or terminate your access to the Service if we suspect that you have engaged in fraudulent activity in connection with the Service.
              </li>
              <li>Verbal or written abuse of any kind (including threats of abuse or retribution) of any IOHUB employee, member, or officer will result in immediate termination.</li>
              <li>We reserve the right, but are not obligated, to limit the availability of the Service, or products or services made available via the Service, to any person, geographic region or jurisdiction. We may exercise this right on a case‑by‑case basis.</li>
              <li>We reserve the right to provide our Service to your competitors and make no promise of exclusivity in any particular market segment. You further acknowledge and agree that IOHUB’s employees and contractors may also use the Service and that they may compete with you, although they may not use your confidential information in doing so.</li>
            </ol>
					</div>
					<div className="doc-para-item">
            <h2>5. Supported Platforms</h2>
            <ol>
              <li>In order for the Service to access the Supported Platforms, you must install the Supported Platform application on your IOHUB Account. Supported Platform applications are available in the IOHUB Workflow. If the application is installed on your IOHUB Account, you will automatically have access to it via the Service.</li>
              <li>You acknowledge that the Service interoperates with several Supported Platforms, and that the Service is highly dependent on the availability of such Supported Platforms. If at any time any Supported Platform becomes unavailable or ceases to make their services available to IOHUB on reasonable terms, IOHUB may cease to provide such features to you without notice and without entitling you to any refund, credit, or other compensation.</li>
              <li>You understand that by using the Service you may be exposed to third‑party content or information that might be unlawful, offensive, harmful, inaccurate or otherwise objectionable. IOHUB does not own, control, or review this content, and IOHUB has no obligation to preview, verify, flag, modify, filter, or remove any third-party content, even if requested to do so, although IOHUB may do so in its sole discretion. Your use of Supported Platforms is at your sole risk, and IOHUB shall not be liable to you or any third party in relation to third-party content that may be accessed via the Service.
              </li>
            </ol>
					</div>
					<div className="doc-para-item">
            <h2>6. Third Party Services</h2>
            <ol>
              <li>You acknowledge that the Service may enable or assist you in accessing, interacting with and/or purchasing services from Supported Platforms and other third parties via third party software, applications, products, services or website links (collectively, “Third Party Services”) for your consideration or use. Third Party Services are made available only as a convenience, and your purchase, access or use of any such Third Party Services is solely between you and the applicable Third Party Service provider (“Third Party Provider”). Any use by you of Third Party Services offered through the Service is entirely at your own risk and discretion. In addition to these Terms of Service, you must read and agree to the additional service‑specific terms and/or privacy policies applicable such Third Party Services before you use them in connection with the Service.</li>
              <li>We do not provide any warranties with respect to Third Party Services. You acknowledge that IOHUB has no control over Third Party Services, and shall not be responsible or liable to anyone for such Third Party Services. Any issues caused by or related to Third Party Services need to be brought to the applicable Third Party Service Provider and will not be dealt with by IOHUB. The availability of Third Party Services on the Site, in the IOHUB Workflow or enabling the use of such Third Party Services with the Service does not constitute or imply an endorsement, authorization, sponsorship, or affiliation by or with IOHUB. IOHUB strongly recommends that you seek specialist advice before using or relying on Third Party Services to ensure they will meet your needs.</li>
              <li>IOHUB is not responsible for any ads, social posts or messages that are rejected or undelivered by a Third Party Service, and IOHUB is not responsible for managing unsubscribe settings. All ad processes and unsubscribe settings are managed by and in the complete discretion of the Third Party Service and IOHUB has no knowledge of or control over such processes. Any questions relating to the Third Party Service, including rejected or undelivered ads or unsubscribe settings, must be directed to the Third Party Provider.</li>
              <li>If you install or enable a Third Party Service for use with the Service, you grant us permission to allow the applicable Third Party Provider to access your data and to take any other actions as required for the interoperation of the Third Party Service with the Service, and any exchange of data or other interaction between you and the Third Party Provider is solely between you and such Third Party Provider. IOHUB is not responsible for any disclosure, modification or deletion of your data or Customer Content, or for any corresponding losses or damages you may suffer, as a result of access by a Third Party Service or a Third Party Provider to your data or Customer Content.</li>
              <li>Under no circumstances shall IOHUB be liable for any direct, indirect, incidental, special, consequential, punitive, extraordinary, exemplary or other damages whatsoever, that result from any Third Party Services or your contractual relationship with any Third Party Provider. These limitations shall apply even if IOHUB has been advised of the possibility of such damages. The foregoing limitations shall apply to the fullest extent permitted by applicable law.</li>
              <li>You agree to indemnify and hold us and (as applicable) our parent, subsidiaries, affiliates, IOHUB partners, officers, directors, agents, employees, and suppliers harmless from any claim or demand, including reasonable attorneys’ fees, arising out of your use of a Third Party Service or your relationship with a Third Party Provider.</li>
              <li>For clarity, the applications developed, supported and maintained by IOHUB, and for the purposes of this Agreement, IOHUB is deemed to be the Third Party Provider of those applications.</li>
            </ol>
					</div>
					<div className="doc-para-item">
            <h2>7. Intellectual Property</h2>
            <ol>
              <li>You grant IOHUB a limited, worldwide, non‑exclusive license, without a right of sublicense, to access, use, reproduce, electronically distribute, transmit, perform, format, display, store, archive, and index the Customer Content for the purpose of supporting your use of the Service and providing Service to you. IOHUB may also use Customer Content for the purpose of supporting and developing the IOHUB website or applications, provided that when doing so, IOHUB shall only use Customer Content in an anonymized and aggregated way. Subject only to the limited license expressly granted herein, you shall retain all right, title and interest in and to the Customer Content and all intellectual property rights therein. Nothing in this Agreement will confer on IOHUB any right of ownership or interest in the Customer Content.
              </li>
              <li>You are solely responsible for the Customer Content that you upload, publish, display, link to, or otherwise make available via the Service, and you agree that IOHUB only allows for the online distribution and publication of the Customer Content. IOHUB will not review, share, distribute, or reference any Customer Content except as provided herein, as provided in IOHUB’s privacy policy, or as may be required by law. Notwithstanding the foregoing, IOHUB retains the authority to remove any Customer Content uploaded that it deems in violation of these Terms of Service, at its sole discretion.</li>
              <li>You acknowledge and agree that the Service, including without limitation, any associated software, documentation, designs, applications, websites, tools and products, any modifications, enhancements and updates thereto, and all intellectual property rights therein are exclusively owned by IOHUB.</li>
            </ol>
					</div>
					<div className="doc-para-item">
            <h2>8. Privacy and Data Protection</h2>
            <ol>
              <li>IOHUB is firmly committed to protecting the privacy of your personal information and the personal information of your customers. By using the Service, you acknowledge and agree that IOHUB’s collection, usage and disclosure of this personal information is governed by our Privacy Policy.</li>
              <li>Additionally, if you are not in Vietnam, you must obey the laws of your region or country.</li>
            </ol>
					</div>
					<div className="doc-para-item">
            <h2>9. Fees</h2>
            <ol>
              <li>You can pay the service fee to IOHUB by cash or transfer directly to us or through the payment gateway in IOHUB. After payment, the system will send an online invoice to your email (that you provided when registering). The system will send a notice of the payment term to your email 3 days (or more) in advance. If you do not pay the fee on time, you will have 15 days of use and after 15 days, your account will be limited in functionality. After 6 months, your account may be automatically canceled.</li>
              <li>If you are not in Vietnam, you must obey the laws and taxes of your region or country.</li>
              <li>We may change our fees at any time by posting a new pricing structure to our website and/or sending you a notification by email.</li>
              <li>We does not support refund</li>
            </ol>
					</div>
					<div className="doc-para-item">
            <h2>10. User Comments, Feedback and Other Submissions</h2>
            <ol>
              <li>If you submit any ideas, suggestions, comments, proposals, plans, or other content or submissions to us, whether online, via social media, by email or otherwise (collectively, “Comments”), you agree that we may, at any time, without restriction, edit, copy, publish, distribute, translate and otherwise use in any medium any Comments that you forward to us. We are under no obligation 
                <ul>
                  <li><i>(a)</i> to use or rely on any Comments; </li>
                  <li><i>(b)</i> to maintain any Comments in confidence (unless such comments contain Personal Information (as defined in the Privacy Policy)); </li>
                  <li><i>(c)</i> to pay compensation for any Comments; or </li>
                  <li><i>(d)</i> to respond to any Comments. </li>
                </ul>
              </li>
              <li>You agree that your Comments will not violate any right of any third‑party, including copyright, trademark, privacy, personality or other, intellectual property, personal or proprietary right. You further agree that your Comments will not contain libelous or otherwise unlawful, abusive, hateful or obscene material, or contain any computer virus or other malware that could in any way affect the operation of the Service or any related website or application. You may not use a false e‑mail address, pretend to be someone other than yourself, or otherwise mislead us or third‑parties as to the origin of any Comments. You are solely responsible for any Comments you make and their accuracy. We take no responsibility and assume no liability for any Comments posted by you or any third‑party.</li>
            </ol>
					</div>
					<div className="doc-para-item">
            <h2>11. Prohibited Uses</h2>
            <ol>
              <li>In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from submitting Customer Content or using the Service:
                <ul>
                  <li><i>(a)</i> to violate any international, federal, or local laws, regulations, rules, or ordinances; </li>
                  <li><i>(b)</i> to infringe upon or violate our intellectual property rights or the intellectual property rights of others;  </li>
                  <li><i>(c)</i> upload to, or transmit from, the Service any data, file, software, or link that contains or redirects to a virus, Trojan horse, worm, or other harmful component;  </li>
                  <li><i>(d)</i> to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; </li>
                  <li><i>(e)</i> to submit false or misleading information;  </li>
                  <li><i>(f)</i> to upload or transmit viruses or any other type of malicious or destructive code;  </li>
                  <li><i>(g)</i> to collect or track the personal information of others;  </li>
                  <li><i>(h)</i> to spam, phish, pharm, pretext, spider, crawl, or scrape;  </li>
                  <li><i>(i)</i> for any obscene or immoral purpose;  </li>
                  <li><i>(j)</i> to interfere with or circumvent the security features of the Service or any related website or application, other website or application, or the Internet;  </li>
                  <li><i>(k)</i> to attempt to reverse engineer, de‑compile, hack, disable, interfere with, disassemble, copy, or disrupt the integrity or the performance of the Service; or  </li>
                  <li><i>(l)</i> to access the Service in order to build a competitive product or service or copy any ideas, features, functions, or graphics of the Service </li>
                  <li><i>(m)</i> Sell, license, or purchase Data on the platform. </li>
                </ul>
              </li>
            </ol>
					</div>
					<div className="doc-para-item">
            <h2>12. Disclaimer of Warranties; Limitation of Liability</h2>
            <ol>
              <li>YOU EXPRESSLY UNDERSTAND AND AGREE THAT IOHUB SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES RESULTING FROM THE USE OF OR INABILITY TO USE THE SERVICE. BECAUSE SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR THE LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN SUCH JURISDICTIONS, OUR LIABILITY SHALL BE LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW.</li>
              <li>YOU AGREE TO INDEMNIFY, DEFEND AND HOLD HARMLESS IOHUB, ITS AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, AND LICENSORS FROM ALL CLAIMS, LOSSES, DAMAGES, PENALTIES, LIABILITY AND COSTS, INCLUDING REASONABLE ATTORNEYS’ FEES, OF ANY KIND OR NATURE WHICH ARE IN CONNECTION ARE ARISING OUT OF A CLAIM:
                <ul>
                  <li><i>(A)</i> ALLEGING THAT THE CUSTOMER CONTENT OR YOUR USE OF THE SERVICE INFRINGES OR VIOLATES THE INTELLECTUAL PROPERTY RIGHTS, PRIVACY RIGHTS, OR OTHER RIGHTS OF A THIRD PARTY OR VIOLATES APPLICABLE LAW; </li>
                  <li><i>(B)</i> ARISING OUT OF YOUR BREACH OF THESE TERMS OF SERVICE OR THE DOCUMENTS THEY INCORPORATE BY REFERENCE; </li>
                  <li><i>(C)</i> ARISING OUT OF YOUR USE OF A THIRD PARTY SERVICE OR YOUR RELATIONSHIP WITH A THIRD PARTY PROVIDER; OR </li>
                  <li><i>(D)</i> ALLEGING THAT ANY COMMUNICATIONS FACILITATED THROUGH THE SERVICES BREACHED APPLICABLE LAW.</li>
                </ul>
              </li>
              <li>YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT ANY WARRANTY OR CONDITION, EXPRESS, IMPLIED OR STATUTORY.</li>
              <li>IOHUB DOES NOT WARRANT THAT 
                <ul>
                  <li><i>(A)</i> THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR‑FREE;</li> 
                  <li><i>(B)</i> THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICE WILL BE ACCURATE OR RELIABLE; OR </li>
                  <li><i>(C)</i> THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICE WILL MEET YOUR EXPECTATIONS, OR THAT ANY ERRORS IN THE SERVICE WILL BE CORRECTED.
                  </li>
                </ul>
              </li>
              <li>ANY RELIANCE ON THE MATERIAL OR INFORMATION MADE AVAILABLE THROUGH THE SERVICE IS AT YOUR OWN RISK.</li>
              <li>OCCASIONALLY THERE MAY BE INFORMATION AVAILABLE THROUGH THE SERVICE THAT CONTAINS TYPOGRAPHICAL ERRORS, INACCURACIES OR OMISSIONS THAT MAY RELATE TO PRODUCT DESCRIPTIONS, PRICING, PROMOTIONS, OFFERS, PRODUCT SHIPPING CHARGES, TRANSIT TIMES AND AVAILABILITY.</li>
              <li>WE UNDERTAKE NO OBLIGATION TO UPDATE, AMEND OR CLARIFY INFORMATION IN THE SERVICE, INCLUDING WITHOUT LIMITATION, PRICING INFORMATION, EXCEPT AS REQUIRED BY LAW. NO SPECIFIED UPDATE OR REFRESH DATE APPLIED IN THE SERVICE, SHOULD BE TAKEN TO INDICATE THAT ALL INFORMATION IN THE SERVICE HAS BEEN MODIFIED OR UPDATED.</li>
            </ol>
					</div>
					<div className="doc-para-item">
            <h2>13. Governing Law</h2>
						<p>These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws of Vietnam. IOHUB's mailing address is 41 Trung Luong 8 street, Hoa Xuan Ward, Cam Le District, Da Nang City, Vietnam.
            </p>
					</div>
					<div className="doc-para-item">
            <h2>14. Severability</h2>
            <p>In the event that any provision of these Terms of Service is determined by a court of competent jurisdiction to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, the unenforceable portion shall be deemed to be severed from these Terms of Service, and such determination shall not affect the validity and enforceability of any other remaining provisions.
            </p>
					</div>
					<div className="doc-para-item">
            <h2>15. Termination</h2>
            <p>These Terms of Service are effective unless and until terminated by either you or us. If you no longer wish to use the Service, you may terminate your IOHUB Account in accordance with the applicable terms. If in our sole discretion you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Service, we also may terminate these Terms of Service at any time without notice and accordingly may deny you access to the Service, your IOHUB Account or any part thereof. The obligations (including payment obligations, if any) and liabilities incurred prior to the termination date shall survive the termination of these Terms of Service for all purposes.
            </p>
					</div>
					<div className="doc-para-item">
            <h2>16. Waiver and Entire Agreement</h2>
            <ol>
              <li>The failure by us to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision.</li>
              <li>These Terms of Service and any policies or operating rules incorporated into these Terms of Service or posted by us in respect to the Service constitutes the entire agreement and understanding between you and us, and govern your use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Service).
              </li>
            </ol>
					</div>
        </div>
      </section>
    </>
  );
};

export default TermsOfServiceEN;
